var slider = tns({
	container: '.brands-slider',
	items: 7,
	autoplay: false,
	controls: false,
	nav: false,
	autoplayButtonOutput: false,
	controlsContainer: "#customized-controls",
	responsive: {
		1200: {
			items: 7,
			controls: false,

		},
		1000: {
			items: 5,
			controls: false,
		},
		0: {
			items: 2,
			controls: true,
		}
	}
});
